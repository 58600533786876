import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { isNumber } from 'lodash';
import { Router } from '@angular/router';

import Dictionary from 'src/app/core/utils/dictionary.utils';
import { RevenueResponse } from '../../entities/revenue-response';
import { ProjectionTileComponent } from '../../projection-tile.component';
import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { RequestPayload } from 'src/app/shared/services/entities/request-payload';
import { ProjectionTileConstants } from '../../constants/projection-tile.constants';
import { TileUtils } from '../../../utils/tile.utils';
import {
  Periods,
  TimePeriodCodes,
} from 'src/app/shared/constants/filters.constants';

import { ProjectionTileService } from 'src/app/shared/services/tiles/projection-tile.service';
import { FiltersService } from 'src/app/shared/services/filters.service';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { BetaMessageService } from 'src/app/shared/services/beta-message.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';

@Component({
  selector: 'app-revenue-tile',
  templateUrl: '../../projection-tile.component.html',
  styleUrls: ['../../projection-tile.component.scss'],
})
export class RevenueTileComponent
  extends ProjectionTileComponent
  implements OnInit
{
  constructor(
    private projectionTileService: ProjectionTileService,
    private filterService: FiltersService,
    private errorHandlerService: ErrorHandlerService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    betaMessage: BetaMessageService,
    appStateService: AppStateService
  ) {
    super(
      projectionTileService,
      filterService,
      errorHandlerService,
      changeDetector,
      router,
      MetricType.Revenue,
      betaMessage,
      appStateService
    );
  }

  ngOnInit(): void {
    super.initializeComponent((x: RequestPayload) => {
      super.setTempTitle(
        this.isActuals
          ? ProjectionTileConstants.Revenue.title.actuals
          : ProjectionTileConstants.Revenue.title.projection
      );

      this.betaMessage.isCloudSubject$.subscribe((betaFlag) => {
        this.isBetaTile = betaFlag;
        this.isCloud =
          (betaFlag && !this.isFlipFlopFlagEnabled) ||
          (!betaFlag && this.isFlipFlopFlagEnabled);

        super.loadStarted();
        super.clearTile();
        super.setTempItems(ProjectionTileConstants.Revenue.items);
        this.projectionTileService
          .getTileDataByType<RevenueResponse>(
            this.type,
            x,
            this.isPercentage,
            this.isCloud
          )
          .then((y: RevenueResponse) => {
            if (isNumber(y)) {
              throw new HttpErrorResponse({
                status: y,
              });
            }

            const params = new Dictionary<any>();
            params.add('metric', this.type);
            params.add('allowPercentage', this.allowPercentage);
            params.add('isPercentage', this.isPercentage);
            params.add('isActuals', this.isActuals);
            const isSingleMonth: boolean = [
              TimePeriodCodes.CurrentMonth,
              TimePeriodCodes.MonthToDate,
            ].includes(this.selectedFilters.timeframe.code);
            params.add(
              'period',
              isSingleMonth ? Periods.Month.id : Periods.Quarter.id
            );

            this.projectionTile = TileUtils.getItems<RevenueResponse>(
              ProjectionTileConstants.Revenue,
              y,
              params
            );
          })
          .catch((error: HttpErrorResponse) => {
            super.handleTileError(error);
          })
          .finally(() => {
            super.loadCompleted();
          });
      });
    });
  }
}
