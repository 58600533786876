import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep, isNumber } from 'lodash';

import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { TrendTileConstants } from './constants/trend-tile.constants';
import { RequestPayload } from 'src/app/shared/services/entities/request-payload';
import { TrendTileResponse } from '../entities/trend-tile-response';
import { TrendFilters } from './components/trend-filters/entities/trend-filters';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { TrendChartConfig } from './components/trend-chart/entities/trend-chart-config';
import { TrendTileDefinition } from '../entities/trend-tile-definition';
import { TrendFiltersConfig } from './components/trend-filters/entities/trend-filters-config';
import { TrendModalConfig } from '../trend-modal/entities/trend-modal-config';
import {
  TrendModalFiltersChanged,
  TrendModalViewTypeChanged,
} from '../trend-modal/entities/trend-modal-events';
import { TrendUtils } from '../utils/trend.utils';
import { TrendFiltersChanged } from './components/trend-filters/entities/trend-filters-events';
import { GroupsBy } from 'src/app/shared/constants/filters.constants';
import { CustomerFilters } from 'src/app/shared/services/entities/filters/customer-filters';
import { Wmu } from 'src/app/shared/services/entities/filters/wmu';
import { Industry } from 'src/app/shared/services/entities/filters/industry';

import { TrendTileService } from 'src/app/shared/services/tiles/trend-tile.service';
import { TrendModalService } from 'src/app/shared/services/tiles/trend-modal.service';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { MetricsOptions } from 'src/app/shared/constants/grid.constants';
import { BetaMessageService } from 'src/app/shared/services/beta-message.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';

@Component({
  selector: 'app-trend-tile',
  templateUrl: './trend-tile.component.html',
  styleUrls: ['./trend-tile.component.scss'],
})
export class TrendTileComponent implements OnDestroy, OnChanges, OnInit {
  subscription = new Subscription();
  @Input() type: MetricType;
  @Input() onOpenTrendModalEmittedObs: Observable<boolean>;

  betaMessage: BetaMessageService;
  appStateService: AppStateService;
  isCloud: boolean;
  isFlipFlopFlagEnabled: boolean;
  isBeta = false;

  id: string = uuidv4();
  loaded: boolean;
  hasErrors: boolean;
  errorMessage: string;
  response: TrendTileResponse;
  filters: Filters;
  customerFilters: CustomerFilters;
  selectedFilters: SelectedFilters;
  trendFilters: TrendFilters;
  trendFiltersConfig: TrendFiltersConfig;
  trendChartConfig: TrendChartConfig;

  types: Array<TextValuePair> = [];
  selectedType: TextValuePair;

  faCheckCircle = faCheckCircle;

  constructor(
    private trendTileService: TrendTileService,
    private errorHandlerService: ErrorHandlerService,
    private trendModalService: TrendModalService,
    private baseChangeDetector: ChangeDetectorRef,
    betaMessage: BetaMessageService,
    appStateService: AppStateService
  ) {
    this.betaMessage = betaMessage;
    this.appStateService = appStateService;
  }

  ngOnInit(): void {
    this.subscription.add(
      this.trendModalService.filtersChanged.subscribe(
        (x: TrendModalFiltersChanged) => {
          this.onTrendModalFiltersChanged(x);
        }
      )
    );

    this.subscription.add(
      this.trendModalService.viewTypeChanged.subscribe(
        (x: TrendModalViewTypeChanged) => {
          if (x.tileParentId === this.id) {
            this.onOpenTrendModalByViewType(x.isChartView);
          }
        }
      )
    );

    this.subscription.add(
      this.appStateService.appStateChanged.subscribe((appState) => {
        this.isFlipFlopFlagEnabled = appState.gcpFlipFlopFlagEnabled;
      })
    );

    this.subscription.add(
      this.trendTileService.metricChanged.subscribe((x: TextValuePair) => {
        let type: TextValuePair = null;

        switch (x.value) {
          case MetricsOptions.Sales:
            type = this.types.find(
              (y: TextValuePair) => y.value === TrendTileConstants.Sales.type
            );
            break;
          case MetricsOptions.Revenue:
            type = this.types.find(
              (y: TextValuePair) => y.value === TrendTileConstants.Revenue.type
            );
            break;
          case MetricsOptions.Cci:
          case MetricsOptions.CciPercentage:
            type = this.types.find(
              (y: TextValuePair) => y.value === TrendTileConstants.Cci.type
            );
            break;
          default:
            break;
        }

        const trendFilters: TrendFilters = this.trendFilters
          ? cloneDeep(this.trendFilters)
          : new TrendFilters();
        trendFilters.isPercentage = x.value === MetricsOptions.CciPercentage;

        this.onTypeChanged(type, trendFilters, true);
      })
    );

    if (this.onOpenTrendModalEmittedObs) {
      this.subscription.add(
        this.onOpenTrendModalEmittedObs.subscribe((x: boolean) => {
          this.onOpenTrendModalByViewType(x);
        })
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type && changes.type.currentValue) {
      this.getTypeList();
      this.setFilters();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setFilters(defaults?: TrendFilters, forceApply?: boolean): void {
    const definition: TrendTileDefinition = TrendUtils.getDefinition(this.type);

    this.trendFilters = defaults ?? this.trendFilters;

    if (
      this.trendFilters &&
      this.trendFiltersConfig?.definition.type !== this.type
    ) {
      this.trendFilters.isPercentage = definition.allowPercentage;
    }

    this.trendFiltersConfig = new TrendFiltersConfig({
      componentName: this.selectedType.text,
      definition: definition,
      defaultFilters: defaults ?? null,
      exportable: false,
      forceApply,
    });
  }

  initializeComponent(callback?: () => void): void {
    this.betaMessage.isCloudSubject$.subscribe((betaFlag) => {
      this.isBeta = betaFlag;
      this.isCloud =
        (betaFlag && !this.isFlipFlopFlagEnabled) ||
        (!betaFlag && this.isFlipFlopFlagEnabled);
      this.loadStarted();

      this.clearTile();
      this.trendTileService
        .getTileDataByType(
          this.type,
          RequestPayload.createRequest(
            this.selectedFilters,
            this.getExtraParams()
          ),
          this.trendFilters.isPercentage,
          this.isCloud
        )
        .then((z: TrendTileResponse) => {
          if (isNumber(z)) {
            throw new HttpErrorResponse({
              status: z,
            });
          }

          this.response = z;

          this.trendChartConfig = new TrendChartConfig({
            id: this.id,
            filters: this.filters,
            selectedFilters: this.selectedFilters,
            trendFilters: this.trendFilters,
            response: z,
            type: this.type,
            definition: this.trendFiltersConfig.definition,
            isExpanded: false,
          });

          if (callback) {
            callback();
            callback = null;
          }
        })
        .catch((error: HttpErrorResponse) => {
          this.handleTileError(error);
        })
        .finally(() => {
          this.loadCompleted();
        });
    });
  }

  loadStarted(): void {
    this.loaded = false;
    this.baseChangeDetector.detectChanges();
  }

  loadCompleted(): void {
    this.loaded = true;
    this.baseChangeDetector.detectChanges();
  }

  handleTileError(error: HttpErrorResponse): void {
    this.hasErrors = true;

    switch (error.status as any) {
      case 504:
        this.errorMessage =
          "We couldn't retrieve the data in time. Try refreshing your browser to fix the issue.";
        break;
      default:
        this.errorMessage =
          'There was an issue during the process. Please, try again later.';
        break;
    }

    this.errorHandlerService.handleError(error);
    this.baseChangeDetector.detectChanges();
  }

  clearTile(): void {
    this.hasErrors = false;
    this.errorMessage = '';
  }

  getTypeList(): void {
    const keys: Array<string> = Object.keys(TrendTileConstants);

    keys.forEach((x: string) => {
      const textValuePair = new TextValuePair({
        text: (TrendTileConstants as any)[x].title,
        value: (TrendTileConstants as any)[x].type,
      });

      if (textValuePair.value === this.type) {
        this.selectedType = textValuePair;
      }

      this.types.push(textValuePair);
    });
  }

  getExtraParams(): Record<string, any> {
    const extraParams: Record<string, any> = {
      groupBy: this.trendFilters.getRequestGroupBy(),
      isCumulative: this.trendFilters.isCumulative.toString(),
    };

    switch (this.trendFilters.groupBy.value) {
      case GroupsBy.WMU.id:
        if (this.selectedFilters.wmus?.length === 0) {
          const root: Wmu = this.customerFilters.wmu.find(
            (x: Wmu) => x.PID === null || x.PID === 0
          );
          if (root) {
            extraParams.rolledUpWmuList = this.customerFilters.wmu
              .filter((x: Wmu) => x.PID === root.ID)
              .map((x: Wmu) => x.ID)
              .join(',');
          }
        }
        break;
      case GroupsBy.Industry.id:
        if (this.selectedFilters.industries?.length === 0) {
          extraParams.industryFilter = this.filters.industry
            .filter((x: Industry) => x.EntityLevelNumber === 0)
            .map((x: Industry) => x.EntityCode)
            .join(',');
        }
        break;
    }

    return extraParams;
  }

  onTypeChanged(
    type: TextValuePair,
    trendFilters?: TrendFilters,
    forceApply?: boolean
  ): void {
    this.selectedType = type;
    this.type = this.selectedType.value;
    this.setFilters(trendFilters, forceApply);
  }

  onFiltersSelectionChanged(event: TrendFiltersChanged): void {
    this.trendFilters = event.trendFilters;
    this.filters = event.filters;
    this.selectedFilters = event.selectedFilters;
    this.customerFilters = event.customerFilters;
    this.initializeComponent();
  }

  onExpandSelected(): void {
    this.onOpenTrendModalByViewType(true);
  }

  onFiltersLoadingCompleted(hasLoadingCompleted: boolean): void {
    this.loaded = hasLoadingCompleted;
  }

  onOpenTrendModalByViewType(isChartView: boolean): void {
    this.trendModalService.openTrendModalEmitted.emit(
      new TrendModalConfig({
        isChartView: isChartView,
        response: this.response,
        trendFilters: this.trendFilters,
        type: this.type,
        tileParentId: this.id,
        filters: this.filters,
        selectedFilters: this.selectedFilters,
      })
    );
  }

  onTrendModalFiltersChanged(event: TrendModalFiltersChanged): void {
    if (event && event.trendFilters && this.id === event.tileParentId) {
      // if (
      //   !this.trendFilters.equals(event.trendFilters) ||
      //   this.type !== event.type
      // ) {
      this.type = event.type;
      this.selectedType = this.types.find(
        (x: TextValuePair) => x.value === this.type
      );
      this.setFilters(event.trendFilters);

      if (event.reloadRequired) {
        this.initializeComponent(() => {
          this.onOpenTrendModalByViewType(event.isChartView);
          if (event.callback) {
            event.callback();
          }
        });
      } else if (event.callback) {
        this.onOpenTrendModalByViewType(event.isChartView);
        event.callback();
      }
      // } else if (event.callback) {
      //   event.callback();
      // }
    }
  }
}
