import { ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { isNumber } from 'lodash';

import { GilcRevenueResponse } from '../../entities/gilc-revenue-response';
import { GilcTileComponent } from '../../gilc-tile.component';
import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { RequestPayload } from 'src/app/shared/services/entities/request-payload';
import { GilcTileConstants } from '../../constants/gilc-tile.constants';
import { TileUtils } from '../../../utils/tile.utils';

import { FiltersService } from 'src/app/shared/services/filters.service';
import { GilcTileService } from 'src/app/shared/services/tiles/gilc-tile.service';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { BetaMessageService } from 'src/app/shared/services/beta-message.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';

@Component({
  selector: 'app-gilc-revenue-tile',
  templateUrl: '../../gilc-tile.component.html',
  styleUrls: ['../../gilc-tile.component.scss'],
})
export class RevenueGilcTileComponent
  extends GilcTileComponent
  implements OnInit
{
  @Input() isCloud: boolean;
  constructor(
    private gilcTileService: GilcTileService,
    private filterService: FiltersService,
    private errorHandlerService: ErrorHandlerService,
    private changeDetector: ChangeDetectorRef,
    betaMessage: BetaMessageService,
    appStateService: AppStateService
  ) {
    super(
      gilcTileService,
      filterService,
      errorHandlerService,
      changeDetector,
      MetricType.Revenue,
      betaMessage,
      appStateService
    );
  }

  ngOnInit(): void {
    super.setTempTitle(GilcTileConstants.Revenue.title.common);
    super.initializeComponent((x: RequestPayload) => {
      this.subscription.add(
        this.betaMessage.isCloudSubject$.subscribe((betaFlag) => {
          this.isBeta = betaFlag;
          this.isCloud =
            (betaFlag && !this.isFlipFlopFlagEnabled) ||
            (!betaFlag && this.isFlipFlopFlagEnabled);
          super.loadStarted();
          super.clearTile();
          super.setTempItems(GilcTileConstants.Revenue.items);
          this.gilcTileService
            .getTileDataByType<GilcRevenueResponse>(this.type, x, this.isCloud)
            .then((y: GilcRevenueResponse) => {
              if (isNumber(y)) {
                throw new HttpErrorResponse({
                  status: y,
                });
              }

              this.gilcTile = TileUtils.getItems<GilcRevenueResponse>(
                GilcTileConstants.Revenue,
                y
              );
            })
            .catch((error: HttpErrorResponse) => {
              super.handleTileError(error);
            })
            .finally(() => {
              super.loadCompleted();
            });
        })
      );
    });
  }
}
